import React from "react"
import { faFacebook, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons"

export const social_links = [
  {
    name: "Facebook",
    href: "//www.facebook.com/pages/Lucilles-Oyster-Dive/200515528427",
    icon: faFacebook
  },
  {
    name: "Instagram",
    href: "//www.instagram.com/lucillesfoodtruck/",
    icon: faInstagram
  }
]

export const page_links = {
  en: [
    {
      name: "Contact",
      ref: React.createRef(),
    },
  ],
  fr: [
    {
      name: "Contact",
      ref: React.createRef(),
    },
  ]
}

export const INDEX_PAGEKEYS_CONTACT = 0
export const INDEXLEN_PAGEKEYS      = 1
