import React from "react"
import styled from "@emotion/styled"
import { RichText } from 'prismic-reactjs'

import imgCheckered from "../images/checkered-14.png"

const FlexItem = styled.div`
  flex: 100%;
  padding: 10px 5px;
  @media (min-width: 768px) {
    flex: 20%;
  }
  @media (min-width: 1600px) {
  }
`
const FlexItemLeft = styled.section`
  flex: 3;
  padding: 30px;
  @media (min-width: 768px) {
    padding: 60px 30px 30px 30px;
  }
  @media (min-width: 1600px) {
  }
`
const FlexItemRight = styled.section`
  display: block;
  margin: auto;
  max-width: 120px;
  align-items: center;
  @media (min-width: 1024px) {
  display: flex;
  flex: 1;
  max-width: 100%;
  }
  @media (min-width: 1600px) {
  flex: 1;
  }
`
const Frame = styled.div`
  position: relative;
  flex: 1;
  border-bottom: 4px solid #414141;
`
const ContainerWrap = styled.div`
  display: flex;
  flex-flow: wrap;
`
const Container = styled.div`
  position: relative;
  display: flex;
  @media (max-width: 768px) {
    & div:first-child {
      padding-left: 0;
    }
    & div:last-child {
      padding-right: 0;
    }
  }
  @media (min-width: 768px) {
    top 30px;
  }
  @media (min-width: 768px) {
  }
`
const ContainerBottom = styled.div`
  position: relative;
  display: block;
  @media (max-width: 768px) {
    top 30px;
    & div:first-child {
      padding-left: 0;
    }
    & div:last-child {
      padding-right: 0;
    }
  }
  @media (min-width: 1024px) {
    display: flex;
  }
`
const Image = styled.img`
  display: block;
  margin-bottom: 4px;
  padding: 0;
  border-bottom: 4px solid #00ABD4;
`
const Mask = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(${imgCheckered});
`
const Button = styled.a`
  text-transform: uppercase;
  margin: 40px auto;
  max-width: 120px;
  text-align: center;
  display: block;
  background-color: transparent;
  margin: 40px 0 30px 0;
  padding: 8px 14px;
  color: #00ABD4;
  font-family: 'Oswald';
  font-size: 18px;
  border: 4px solid #00ABD4;
  &:visited {
    color: #060809;
  }
  &:hover {
    color: #414141;
    border: 4px solid #414141;
  }
  @media (min-width: 1024px) {
  }
`

const Frames = ({images}) => {
  let items = []
  if(images) {
    items = images.map(e => (
      <FlexItem>
        <Frame key={e.image.url + e.image.alt}>
          <Mask />
          <Image src={e.image.url} alt={e.image.alt}/>
        </Frame>
      </FlexItem>
    ))
  }
  return items
}
const Grid = ({images, summary, call_to_action_link, call_to_action_label}) => (
  <ContainerWrap>
    <Container>
      <Frames images={images}/>
    </Container>
    <ContainerBottom>
      <FlexItemLeft>
        { RichText.render(summary) }
      </FlexItemLeft>
      <FlexItemRight>
        <Button href={call_to_action_link}>
          { call_to_action_label }
        </Button>
      </FlexItemRight>
    </ContainerBottom>
  </ContainerWrap>
)

export default Grid
